import { createApp } from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import { store, key, bootstrap } from "common-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "common-vue/dist/style.css"

createApp(App)
    .use(bootstrap)
    .use(store, key)
    .use(router)
    .mount("#app");
