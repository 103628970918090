<template>
  <CookieComponent></CookieComponent>
  <nav
    class="navbar navbar-dark bg-info fixed-top navbar-expand-md"
    v-if="!cookieLoad && cookeiEnabled"
  >
    <a class="navbar-brand" href="#" target="_self" @click.pervent>
      <BasketComponent />
      </a
    ><button
      class="navbar-toggler collapsed"
      type="button"
      aria-label="Toggle navigation"
      right
      aria-expanded="false"
      aria-controls="nav-collapse"
      style="overflow-anchor: none"
      @click="showMenu=!showMenu"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse collapse d-none d-md-block"
      id="nav-collapse"
      :show="showMenu"
    >
      <ul class="navbar-nav ml-auto">
        <AuthComponent>
          <li class="nav-item">
            <a class="nav-link" href="/" target="_self">На главную</a>
          </li>
          <li class="nav-item b-nav-dropdown dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              href="#administration"
              ><span>Административный раздел</span></a
            >
            <ul
              class="dropdown-menu dropdown-menu-right"
              tabindex="-1"
              aria-labelledby="administration__BV_toggle_"
              id="administration__BV_toggle_menu_"
            >
              <li class="nav-item">
                <a class="nav-link" href="/admin/users" target="_self"
                  >Пользователи</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin/costs" target="_self"
                  >Стоимость</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin/courts" target="_self"
                  >Корты</a
                >
              </li>
            </ul>
          </li>
        </AuthComponent>
      </ul>
    </div>
    <b-collapse
      class="navbar-collapse d-block d-md-none"
      id="nav-collapse"
      :show="showMenu"
    >
      <ul class="navbar-nav ml-auto">
        <AuthComponent>
          <li class="nav-item">
            <a class="nav-link" href="/" target="_self">На главную</a>
          </li>
          <li class="nav-item b-nav-dropdown dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              href="#administration"
              ><span>Административный раздел</span></a
            >
            <ul
              class="dropdown-menu dropdown-menu-right"
              tabindex="-1"
              aria-labelledby="administration__BV_toggle_"
              id="administration__BV_toggle_menu_"
            >
              <li class="nav-item">
                <a class="nav-link" href="/admin/users" target="_self"
                  >Пользователи</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin/costs" target="_self"
                  >Стоимость</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin/courts" target="_self"
                  >Корты</a
                >
              </li>
            </ul>
          </li>
        </AuthComponent>
      </ul>
    </b-collapse>
  </nav>
  <div class="navbar-margin" v-if="!cookieLoad && cookeiEnabled"></div>
  <router-view />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore, CookieComponent, AuthComponent, BasketComponent, getApi, emitter } from "common-vue";
import { Dropdown, Collapse } from "bootstrap";
const store = useStore();
const showMenu = ref(false);
const cookeiEnabled = computed(() => store.state.cookeiEnabled);
const cookieLoad = computed(() => store.state.cookieLoad);
function getProfile() {
  store.commit('setLoadingProfile', true)
  getApi()
    .get('profile')
    .then((response) => {
      store.commit('setProfile', {
        name: response.data.name,
        accessLevel: response.data.accessLevel,
        id: response.data.id
      })
    })
    .catch(() => {
      store.commit('setProfile', undefined)
    })
    .finally(() => {
      store.commit('setLoadingProfile', false)
      emitter.emit('refreshBasket')
    })
}
onMounted(() => {
  getProfile()
  emitter.on('refreshProfile', () => {
    getProfile()
  })
  const dropdownElementList = document.querySelectorAll(".dropdown-toggle");
  [...dropdownElementList].map(
    (dropdownToggleEl) => new Dropdown(dropdownToggleEl)
  );
  const collapseElementList = document.querySelectorAll(".collapse");
  [...collapseElementList].map(
    (collapseEl) => new Collapse(collapseEl, {
      toggle: true
    })
  );
});
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/_functions.scss";
@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/_mixins.scss";
.float-right {
  float: right;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
::-webkit-scrollbar {
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background-color: #ddd;
}
::-webkit-scrollbar-thumb {
  height: 15px;
  width: 15px;
  border-radius: 8px;
  background-color: #17a2b8;
  border: 1px solid #ddd;
}
body *,
html * {
  transition: width, max-width 0.4s;
}

#app .navbar-brand {
  padding: 0;
  margin-top: -0.5rem;
  z-index: 9;
}
.navbar {
  z-index: 1031 !important;
}
.navbar-nav > li > .dropdown-menu {
  background-color: var(--bs-info);
  border: 0;
  margin-left: 10px;
}

.navbar-margin {
  height: 147px;
}

body,
html {
  min-width: 240px;
  * {
    font-size: 0.8rem;
  }

  h1,
  .h1 {
    font-size: 0.7rem;
  }

  h2,
  .h2 {
    font-size: 0.69rem;
  }

  h3,
  .h3 {
    font-size: 0.68rem;
  }

  h4,
  .h4 {
    font-size: 0.67rem;
  }

  h5,
  .h5 {
    font-size: 0.66rem;
  }

  h6,
  .h6 {
    font-size: 0.65rem;
  }

  button.btn {
    padding: 0.25rem 0.6rem;
    font-size: 0.6rem;
  }

  .navbar-toggler > .navbar-toggler-icon {
    font-size: 1.25rem;
  }
  .tableWrapper {
    .table {
      th,
      td {
        padding: 0.25rem;
      }
    }
  }
}

.tableHead {
  .table {
    margin-bottom: 0;

    * {
      padding: 0.25rem 0.25rem;
    }
  }
}

@include media-breakpoint-up(sm) {
  body,
  html {
    * {
      font-size: 0.85rem;
    }

    h1,
    .h1 {
      font-size: 1.6rem;
    }

    h2,
    .h2 {
      font-size: 1.3rem;
    }

    h3,
    .h3 {
      font-size: 1rem;
    }

    h4,
    .h4 {
      font-size: 0.98rem;
    }

    h5,
    .h5 {
      font-size: 0.94rem;
    }

    h6,
    .h6 {
      font-size: 0.9rem;
    }

    button.btn {
      font-size: 0.85rem;
    }

    .navbar-margin {
      height: 163px;
    }
    .tableWrapper {
      .table {
        th,
        td {
          padding: 0.75rem;
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .no-padding-xs {
    padding: 0 !important;
  }
}

@include media-breakpoint-up(md) {
  body,
  html {
    * {
      font-size: 0.9rem;
    }

    #app nav.navbar .navbar-collapse.d-md-none {
      display: none !important;
    }
    button.btn {
      padding: 0.375rem 0.75rem;
    }

    .navbar-margin {
      height: 172px;
    }
  }
}

@include media-breakpoint-up(lg) {
  body,
  html {
    * {
      font-size: 1rem;
    }
    .navbar-margin {
      height: 175px;
    }
  }
}
.accept-cookie {
  z-index: 9999;
  position: fixed !important;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
}
</style>
