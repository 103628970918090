import { store } from "common-vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: { title: "Бронирование спортивных площадок"},
  },
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    meta: { 
      title: "Бронирование спортивных площадок - раздел администрирования",
      isAdmin: true
    },
    redirect: "/admin/users",
    children: [
        {
            path: "users",
            component: () =>
              import(/* webpackChunkName: "adminUsers" */ "../views/AdminUsersView.vue"),
            meta: { title: "Бронирование спортивных площадок - раздел администрирования - пользователи"},
            children: [
                {
                    path: ":id",
                    component: () =>
                      import(/* webpackChunkName: "adminUser" */ "../views/AdminUserView.vue"),
                    meta: { title: "Бронирование спортивных площадок - раздел администрирования - пользователи - пользователь"},
                }
            ]
        },
        {
            path: "costs",
            component: () =>
              import(/* webpackChunkName: "adminUserCosts" */ "../views/AdminCostsView.vue"),
            meta: { title: "Бронирование спортивных площадок - раздел администрирования - стоимость"},
        },
        {
          path: "courts",
          component: () =>
            import(/* webpackChunkName: "adminUserCosts" */ "../views/AdminCourtsView.vue"),
          meta: { title: "Бронирование спортивных площадок - раздел администрирования - корты"},
      }
    ],
  },
  {
    path: "/404",
    component: () =>
      import(
        /* webpackChunkName: "notfound" */ "../views/ErrorNotFoundView.vue"
      ),
    meta: {
      title: "Бронирование спортивных площадок - страница не найдена"
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta && typeof to.meta.title === "string") {
    document.title = to.meta.title;
  }
  next();
});

router.beforeEach((to, _from, next) => {
  if(to.meta.isAdmin) {
    const waitLoginInterval = setInterval(() => {
      if(!store.state.loadingProfile) {
        if(!store.state.profile || store.state.profile.accessLevel !== 1 ) {
          router.push("/");
        } else {
          next();
        }
        clearInterval(waitLoginInterval);
      }
    }, 100);
  } else {
    next();
  }
});


export default router;
